export interface IHeadersAuthentication {
  Authorization: string
  /* Accept: string | null
    'Content-Type': string | null */
}

export interface IExtrasFetch {
  headers: IHeadersAuthentication
  onResponseError?: Function
}

export class ExtrasFetch implements IExtrasFetch {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public headers: IHeadersAuthentication,
    public onResponseError?: Function,
  ) {}
}

export class HeadersAuthentication implements IHeadersAuthentication {
  // "Content-Type": string | null;
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public Authorization: string /* , public Accept: string | null = null, public contentType: string | null = null */,
  ) {
    // this["Content-Type"] = contentType;
  }
}
